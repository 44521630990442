import React, { useState } from "react";
import styled from "styled-components";
import SupportModalMobile from "./SupportModalMobile";
import Logo from "../assets/images/logo.svg";
import cross from "../assets/images/cross.svg";
import burger from "../assets/images/burger.svg";
import folder from "../assets/images/folder.png";

const LogoBlock = styled.div`
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const LogoImage = styled.img`
  width: 39px;
  height: 48px;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: #282b34;
  padding: 20px;
  border-radius: 20px;
  width: 412px;
  height: 244px;
  max-width: 400px;
  text-align: center;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 25px;
  font-family: Inter;
  @media (max-width: 768px) {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: -5px;
  }
`;

const ModalText = styled.p`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 30px;
  font-family: Inter;
  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const ModalLink = styled.a`
  display: flex;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 700;
  font-family: Inter;
  padding-bottom: 5px;
`;

const HeaderWrapper = styled.header`
  padding: 20px 0 20px 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1136px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const LogoText = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  padding-left: 10px;
  font-family: Inter;
  @media (max-width: 768px) {
    display: none; /* Hide text on mobile */
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 20px;
  margin-left: auto;
  margin-right: 20px;

  a {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
    text-decoration: none;
    font-size: 18px;
    font-family: Inter;
    @media (max-width: 768px) {
      display: none; /* Hide on mobile */
    }
    &:hover {
      color: #ffffff;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    margin-left: 0;
  }
`;

const Button = styled.button`
  background-color: #f1cc06;
  width: 242px;
  height: 52px;
  border: none;
  color: #14161b;
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  font-family: Inter;

  &:hover {
    background-color: #ffe03a;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ButtonMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    font-family: Inter;
    background-color: #f1cc06;
    color: #14161b;
    width: 172px;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 12px;
    padding: 0;
    margin-right: 15px;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #ffe03a;
    }
  }
`;

// const BurgerMenuButton = styled.div`
//   cursor: pointer;
//   display: none; /* Hide by default */

//   @media (max-width: 768px) {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-around;
//     width: 24px;
//     height: 20px;
//     padding: 0 28px 0 8px;

//     div {
//       height: 3px;
//       background-color: white;
//       transition: all 0.3s ease;
//       border-radius: 10px;
//     }
//   }
// `;

const BurgerMenuButton = styled.div`
  cursor: pointer;
  display: none; /* По умолчанию скрыт */

  @media (max-width: 768px) {
    display: flex;
    padding: 0 20px 0 0px;
  }
`;

const BurgerIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const MobileMenuBackdrop = styled.div`
  flex-direction: column;
  position: fixed;
  top: 0;
  left: -60;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const MobileMenuContent = styled(ModalContent)`
  width: 100%;
  height: 100%;
  padding: 100px 0 0 0;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;

  align-items: center;
`;

const MobileMenuLink = styled.a`
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0; /* Spacing between items */
  font-family: Inter;
  line-height: 20px;

  // &:hover {
  //   color: #ffe03a;
  //   text-decoration: underline;
  // }
`;

const SupportContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(61, 65, 73, 1);
  width: 348px;
  height: 80px;
  border-radius: 16px;
  margin: 0 auto;
  padding: 0 10px;
  margin-bottom: 20px;
  cursor: pointer; /* Change cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */

  &:hover {
    background-color: rgba(75, 80, 90, 1); /* New background color on hover */
  }
`;

const SupportTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin-left: 10px;
  margin-top: 19px;
`;

const SupportImg = styled.img`
  padding: 0 5px 0 15px;
  width: 48px;
  height: 48px;
`;

const ArrowImg = styled.img`
  display: flex;
  width: 24px;
  height: 24px;
  padding-right: 10px;
`;

const Text = styled.text`
  display: flex;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 20px;
  font-family: Inter;
  font-weight: 400;
`;

const Header = ({ openModal }) => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileMenuOpenSupport, setIsMobileMenuOpenSupport] = useState(false);

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <HeaderWrapper>
      <>
        <LogoBlock style={{ display: "flex", alignItems: "center" }}>
          <LogoImage src={Logo} alt="MiroLang Logo" />
          <LogoText>MiroLang</LogoText>
        </LogoBlock>

        <NavLinks>
          {/* <a href="#about">О проекте</a> */}
          {/* <a href="#pro">О MiroLang Pro</a> */}
          <a href="#contact" onClick={openModal}>
            Обратная связь
          </a>
        </NavLinks>

        <Button
          onClick={() =>
            window.open(
              "https://apps.apple.com/by/app/mirolang-%D1%83%D1%87%D0%B8-%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B5-%D1%81%D0%BB%D0%BE%D0%B2%D0%B0/id6503367928",
              "_blank"
            )
          }
        >
          Скачать приложение
        </Button>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ButtonMobile
            onClick={() =>
              window.open(
                "https://apps.apple.com/by/app/mirolang-%D1%83%D1%87%D0%B8-%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B5-%D1%81%D0%BB%D0%BE%D0%B2%D0%B0/id6503367928",
                "_blank"
              )
            }
          >
            Скачать приложение
          </ButtonMobile>
          {/* <BurgerMenuButton onClick={toggleMobileMenu}>
            <div />
            <div />
            <div />
          </BurgerMenuButton> */}
          <BurgerMenuButton onClick={toggleMobileMenu}>
            <BurgerIcon src={burger} alt="Меню" />
          </BurgerMenuButton>
        </div>
      </>

      {isMobileMenuOpen && (
        <MobileMenuBackdrop>
          <MobileMenuContent>
            <CloseButton onClick={toggleMobileMenu} src={cross} alt="close" />
            {/* <MobileMenuLink href="#about" onClick={toggleMobileMenu}>
              О Проекте
            </MobileMenuLink>
            <MobileMenuLink href="#pro" onClick={toggleMobileMenu}>
              О MiroLang Pro
            </MobileMenuLink> */}
            <MobileMenuLink
              href="#contact"
              onClick={() => {
                toggleMobileMenu();
                setIsMobileMenuOpenSupport(true);
              }}
            >
              Обратная связь
            </MobileMenuLink>
          </MobileMenuContent>
        </MobileMenuBackdrop>
      )}

      {/* {isMobileMenuOpenSupport && (
        <MobileMenuBackdrop>
          <MobileMenuContent>
            <CloseButton
              onClick={() => setIsMobileMenuOpenSupport(false)}
              src={cross}
              alt="close"
            />
            <ModalTitle>Поддержка</ModalTitle>
            <ModalText>
              Напишите нам, если у вас появились
              <br /> вопросы или предложения.
            </ModalText>
            <SupportContainer>
              <SupportImg src={folder} alt="Folder" />
              <SupportTextContainer>
                <ModalLink href="mailto:support@mirolang.ru">
                  support@mirolang.ru
                </ModalLink>
                <Text>Email для обратной связи</Text>
              </SupportTextContainer>
              <ArrowImg src={arrowRight} alt="Arrow" />
            </SupportContainer>
          </MobileMenuContent>
        </MobileMenuBackdrop>
      )} */}
      {isMobileMenuOpenSupport && (
        <SupportModalMobile
          closeModal={() => setIsMobileMenuOpenSupport(false)}
        />
      )}

      {/* {isModalOpen && (
        <ModalBackdrop onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closeModal} src={cross} alt="close" />
            <ModalTitle>Поддержка</ModalTitle>
            <ModalText>
              Напишите нам, если у вас появились
              <br />
              вопросы или предложения.
            </ModalText>
            <SupportContainer
              onClick={() => window.open("mailto:support@mirolang.ru")}
            >
              <SupportImg src={folder} alt="Folder" />
              <SupportTextContainer>
                <ModalLink as="div">support@mirolang.ru</ModalLink>
                <Text>Email для обратной связи</Text>
              </SupportTextContainer>
              <ArrowImg src={arrowRight} alt="Arrow" />
            </SupportContainer>
          </ModalContent>
        </ModalBackdrop>
      )} */}
    </HeaderWrapper>
  );
};

export default Header;
