// components/Footer.jsx
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom"; // Импортируйте Link
import Logo from "../assets/images/logo.svg";

const FooterContainer = styled.footer`
  padding: 80px 0 0 0;
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 30px 0 0 0;
  }
`;

const Links = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 20px;

  a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    font-size: 20px;

    &:hover {
      color: #ffffff;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const LogoImage = styled.img`
  margin: 30px 0 150px 0;
  width: 56px;
  height: 46px;

  @media (max-width: 768px) {
    margin: 30px 0 80px 0;
  }
`;

const Footer = ({ openModal }) => {
  return (
    <FooterContainer>
      <Links>
        <Link to="/privacy-policy">Политика конфиденциальности</Link>{" "}
        <a
          href="#support"
          onClick={(e) => {
            e.preventDefault();
            openModal();
          }}
        >
          Поддержка
        </a>
      </Links>
      <LogoImage src={Logo} alt="MiroLang Logo" />
    </FooterContainer>
  );
};

export default Footer;
