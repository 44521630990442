import React from "react";
import styled from "styled-components";
import OfflineImage from "../assets/images/words.png";

const BackgroundContainer = styled.div`
  // background-color: #0c0c0d;
  padding: 40px 0 0 0;
  color: #ffffff;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 1126px;
  @media (max-width: 768px) {
    padding: 20px 0 0 0;
  }
`;

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(181, 205, 231, 0.1);
  box-shadow: 0px -0.91px 0.91px 0px rgba(0, 0, 0, 0.3) inset;

  box-shadow: 0px 0.91px 0.91px 0px rgba(255, 255, 255, 0.3) inset;

  padding: 20px;
  text-align: center;
  color: #ffffff;
  border-radius: 48px;
  width: 100%;
  max-width: 1136px;
  position: relative;
  @media (max-width: 768px) {
    width: 350px;
    height: 540px;
    padding: 0;
    margin: 0 auto;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: #000000;
  border-radius: 33px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 335px;
    height: 522px;
    border-radius: 40px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  max-width: 50%;
  padding: 50px 50px 50px 70px;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 30px 5px 10px 5px;

    text-align: center;
  }

  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;

    margin: -20px 0px 70px 0px;

    @media (max-width: 768px) {
      line-height: 1;
      font-size: 14px;
      margin: -10px 0px 5px 0;
    }
  }
`;

const PhoneMockup = styled.div`
  position: absolute;
  right: 85px;
  bottom: 0px;
  @media (max-width: 768px) {
    position: relative;
    display: flex;
    right: 0;
    top: 0;
    padding-bottom: 50px;
  }

  img {
    width: 370px;
    height: 370px;
    border-radius: 16px;

    @media (max-width: 768px) {
      padding-top: 20px;
      width: 302px;
      height: 302px;
      position: relative;
      right: 0;
      top: 0;
    }
  }
`;

const MainText = styled.h1`
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 50px;
  span {
    color: #ffcc00;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const MainTextMobile = styled.h1`
  display: none;

  @media (max-width: 768px) {
    display: block;
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    margin-top: 10px;

    span {
      color: #ffcc00;
    }
  }
`;

const Text = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  line-height: 1.5;
  @media (max-width: 768px) {
    display: none;
  }
`;

const TextMobile = styled.p`
  display: none;

  @media (max-width: 768px) {
    display: block;
    padding-top: 0px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
  }
`;

const OfflineMode = () => {
  return (
    <BackgroundContainer>
      <Section>
        <Content>
          <Card>
            <MainText>
              Оффлайн режим:
              <br /> приложение работает
              <br /> без интернета
            </MainText>
            <MainTextMobile>
              Оффлайн режим:
              <br /> приложение работает без
              <br /> интернета
            </MainTextMobile>
            <Text>
              Вы можете учить слова в самолёте, в поездке
              <br /> и в любом другом месте, где нет интернета.
            </Text>
            <TextMobile>
              Вы можете учить слова в самолёте, в<br /> поездке и в любом другом
              месте, где нет
              <br /> интернета.
            </TextMobile>
          </Card>

          <PhoneMockup>
            <img src={OfflineImage} alt="Phone with app" />
          </PhoneMockup>
        </Content>
      </Section>
    </BackgroundContainer>
  );
};

export default OfflineMode;
