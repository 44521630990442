import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const PageContainer = styled.div`
  padding: 40px;
  color: #ffffff;
  background-color: #0c0c0d;
  min-height: 100vh; /* Задать высоту, чтобы покрыть всю страницу */
`;

const PrivacyPolicyText = styled.div`
  h2 {
    margin-top: 0;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    line-height: 1.6;
  }

  a {
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    font-family: Inter;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      color: #fffddd;
    }
  }
`;

const BackButton = styled(Link)`
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  font-size: 20px;
  display: flex;
  gap: 30px;
  margin-bottom: 40px;

  &:hover {
    color: #ffffff;
  }
`;

const PrivacyPolicyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer>
      <BackButton to="/">Назад на главную</BackButton>
      <PrivacyPolicyText>
        <h2>Политика конфиденциальности</h2>
        <p>
          <strong>Обновлена 18 ноября 2023 г.</strong>
        </p>
        <p>
          Настоящая Политика конфиденциальности (далее – Политика) регулирует
          обработку персональных данных пользователей платформы для изучения
          английской лексики “MiroLang”.
        </p>
        <p>
          Мы заботимся о Вашей конфиденциальности, поэтому уделите несколько
          минут ознакомлению с нашей Политикой и обязательно свяжитесь с нами,
          если возникнут вопросы.
        </p>

        <h3>1. Термины и определения</h3>
        <p>
          1.1 В настоящей Политике используются следующие термины и определения:
        </p>
        <ul>
          <li>Веб-сайт - веб-сайт с доменным именем “mirolang.tilda.ws”.</li>
          <li>
            Вы (Пользователь) - любое физическое лицо, использующее Сервисы и
            достигшее возраста полной дееспособности на основании
            законодательства страны его гражданства.
          </li>
          <li>MiroLang - платформа для изучения английской лексики.</li>
          <li>Я (Оператор) - Физическое лицо Гридюшко Сергей Фёдорович.</li>
          <li>
            Сервисы - платформа для изучения английской лексики “MiroLang”, а
            также страницы веб-сайта с доменным именем “mirolang.tilda.ws”.
          </li>
        </ul>

        <h3>2. Сфера применения</h3>
        <p>
          2.1. Действие настоящей Политики распространяется на любые
          взаимодействия между Оператором и пользователями касательно
          персональных данных при использовании Сервисов.
        </p>
        <p>
          2.2. Настоящая Политика не регулирует и не устанавливает права и
          обязанности третьих лиц.
        </p>
        <p>
          2.3. Не используйте Сервисы, если Вы не согласны с положениями и
          сферой применения настоящей Политики.
        </p>

        <h3>3. Кто определяет цели и методы сбора персональных данных</h3>
        <p>
          3.1. Цели и методы сбора Ваших персональных данных определяются
          следующим лицом: Физическое лицо Гридюшко Сергей Фёдорович.
        </p>

        <h3>4. Персональные данные, которые мы собираем</h3>
        <p>
          4.1. Персональные данные - любая информация, которая может позволить
          прямо или косвенно идентифицировать лицо (например, ФИО,
          онлайн-идентификатор и т.п.).
        </p>
        <p>
          4.2. Персональные данные могут быть собраны как прямо, так и
          опосредованно.
        </p>
        <ul>
          <li>
            (1) Прямой сбор персональных данных осуществляется тогда, когда Вы
            добровольно предоставляете свои персональные данные (например,
            покупая тарифный план в MiroLang).
          </li>
          <li>
            (2) Опосредованный сбор персональных данных может осуществляться
            автоматически при использовании Вами Сервисов, как это случается,
            например, с cookie-файлами.
          </li>
        </ul>
        <p>
          4.3. Мы собираем необходимый минимум Ваших персональных данных в
          целях, предусмотренных настоящей Политикой:
        </p>
        <ul>
          <li>
            (1) пользовательские данные: IP-адрес, взаимодействия пользователя с
            Сервисами, а также (в случае регистрации в Сервисах) адрес
            электронной почты;
          </li>
          <li>
            (2) данные об устройстве: страна, язык, часовой пояс, версия
            Сервисов, версия операционной системы, идентификатор устройства;
          </li>
          <li>
            (3) данные о приложении: идентификатор Сервисов, события Сервисов,
            встроенные покупки и подписки в Сервисах, диагностическая
            информация;
          </li>
          <li>
            (4) платёжная информация: список транзакций пользователя в Сервисах.
          </li>
        </ul>
        <p>
          4.4. Мы не храним номера банковских карт, платёжные реквизиты или иную
          чувствительную платёжную информацию Пользователей:
        </p>
        <ul>
          <li>
            (1) Безопасность платежей, совершённых на Веб-сайте, гарантирует ООО
            НКО «ЮМани». Вся информация, передаваемая в процессе платежа, строго
            конфиденциальна.
          </li>
          <li>
            (2) Совершая платежи в магазинах приложений (Google Play, App Store,
            App Gallery), Вы принимаете их условия использования и политики
            конфиденциальности.
          </li>
        </ul>

        <h3>5. Обработка, хранение и защита данных</h3>
        <p>
          5.1. Под “обработкой” понимается любое действие, связанное с Вашими
          персональными данными.
        </p>
        <p>
          5.2. Мы обрабатываем Ваши данные исключительно в следующих целях и на
          следующих правовых основаниях:
        </p>
        <ul>
          <li>
            Тип данных: Пользовательские данные
            <br />
            Цели обработки: Мы обрабатываем Ваши пользовательские данные для
            того, чтобы предоставлять Вам возможность использовать наши Сервисы.
            <br />
            Правовые основания: Юридический договор в виде наших Условий
            использования.
          </li>
          <li>
            Тип данных: Данные об устройстве
            <br />
            Цели обработки: Мы обрабатываем Ваши данные об устройстве для того,
            чтобы показывать Вам персонализированную рекламу.
            <br />
            Правовые основания: Ваше согласие.
            <br />
            Период хранения: 180 дней.
          </li>
          <li>
            Тип данных: Данные о приложении
            <br />
            Цели обработки: Мы обрабатываем Ваши данные о приложении для того,
            чтобы постоянно улучшать наши Сервисы.
            <br />
            Правовые основания: Законный интерес улучшать наши Сервисы.
            <br />
            Период хранения: 180 дней.
          </li>
          <li>
            Тип данных: Платежная информация
            <br />
            Цели обработки: Мы обрабатываем Вашу платежную информацию для того,
            чтобы предоставлять Вам возможность использовать платные функции
            наших Сервисов.
            <br />
            Правовые основания: Юридический договор в виде наших Условий
            использования.
            <br />
            Период хранения: 10 лет.
          </li>
        </ul>
        <p>
          5.3. В случае обезличивания Ваших персональных данных мы можем
          использовать их для любых иных целей.
        </p>
        <p>
          5.4. Мы не используем автоматизированных средств принятия решений
          (включая профайлинг) в рамках обработки персональных данных.
        </p>
        <p>
          5.5. Ваши персональные данные будут храниться на серверах наших
          партнеров, сервисы которых мы используем для обеспечения работы наших
          Сервисов.
        </p>
        <p>
          5.6. Наши работники также соблюдают все необходимые и доступные нам
          организационные, юридические и технические меры по защите Ваших
          персональных данных.
        </p>
        <p>
          5.7. Если Ваши персональные данные будут переданы третьей стороне, то
          время их хранения будет определяться в соответствии с политикой
          конфиденциальности такой третьей стороны.
        </p>
        <p>
          5.8. Любые персональные данные, собираемые и обрабатываемые в рамках
          настоящей Политики, подлежат надлежащей защите до моментов:
        </p>
        <ul>
          <li>(1) когда Вы предоставляете нам согласие на их разглашение;</li>
          <li>(2) обезличивания таких персональных данных;</li>
          <li>
            (3) до момента раскрытия информации в соответствии с применимым
            правом.
          </li>
        </ul>
        <p>
          5.9. Мы делаем все возможное с нашей стороны для обеспечения защиты
          Ваших персональных данных.
        </p>

        <h3>6. Передача и раскрытие</h3>
        <p>
          6.1. Доступ к Вашим персональным данным могут иметь следующие третьи
          лица:
        </p>
        <ul>
          <li>Физическое лицо Гридюшко Сергей Фёдорович</li>
          <li>Адрес: Беларусь, г. Минск, ул. Солтыса 82-59</li>
        </ul>
        <p>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Политика конфиденциальности Google
          </a>
          : Google может иметь доступ к персональным данным, которые Вы нам
          предоставляете, поскольку мы используем ряд их сервисов: Firebase
          Crashlytics, Firebase A/B Testing, Google Analytics for Firebase и
          т.д.
        </p>

        <h3>7. Персональные данные детей</h3>
        <p>
          7.1. Мы не предоставляем право пользования нашими Сервисами лицам, не
          достигшим возраста полной дееспособности.
        </p>

        <h3>8. Права пользователей</h3>
        <p>
          8.1. Права пользователей касательно сбора и обработки персональных
          данных определяются в соответствии с применимым правом.
        </p>
        <p>
          8.2. Ваши права касательно сбора и обработки персональных данных могут
          определяться в соответствии с применимым к конкретным случаям
          обработки правом.
        </p>
        <p>
          8.3. Для реализации указанных выше прав, направьте заявку на адрес
          электронной почты:{" "}
          <a onClick={() => window.open("mailto:sergey.gridyushko@gmail.com")}>
            sergey.gridyushko@gmail.com
          </a>
          .
        </p>

        <h3>9. Заключительные положения</h3>
        <p>
          9.1. Настоящая Политика может быть дополнена и (или) изменена в любое
          время работы Сервисов.
        </p>
        <p>
          9.2. Политика представляет собой соглашение между нами и Пользователем
          в отношении использования Сервисов, любые другие ранее существовавшие
          письменные или устные соглашения или договоренности в отношении такого
          использования, настоящим аннулируются.
        </p>
        <p>
          9.3. Если какое-либо условие Политики является недействительным или
          неисполнимым, иные условия остаются действительными и исполнимыми в
          полной мере, разрешенной применимым законодательством.
        </p>
        <p>
          9.4. Невозможность принудить Вас к строгому соблюдению Политики не
          может быть истолкована как наш отказ от любого положения или любого
          права Политики.
        </p>
        <p>
          9.5. Настоящая политика составлена на русском и английском языках. В
          случае любых текстуальных расхождений преобладает версия на русском
          языке.
        </p>
      </PrivacyPolicyText>
    </PageContainer>
  );
};

export default PrivacyPolicyPage;
