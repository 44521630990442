// SupportModalMobile.js
import React from "react";
import styled from "styled-components";
import cross from "../assets/images/cross.svg";
import folder from "../assets/images/folder.png";
import arrowRight from "../assets/images/arrowRight.svg";

const MobileMenuBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const MobileMenuContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 100px 0 0 0;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  color: #ffffff;
  margin-bottom: -10px;
  font-family: Inter;
`;

const ModalText = styled.p`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 25px;
  font-family: Inter;
  text-align: center;
`;

const SupportContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(28, 31, 38, 1);
  width: 348px;
  height: 80px;
  border-radius: 16px;
  margin: 0 auto;
  padding: 0 10px;
  margin-bottom: 20px;
  cursor: pointer; /* Change cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */

  &:hover {
    background-color: rgba(75, 80, 90, 1); /* New background color on hover */
  }
`;

const SupportTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin-left: 10px;
  margin-top: 19px;
`;

const SupportImg = styled.img`
  padding: 0 5px 0 15px;
  width: 48px;
  height: 48px;
`;

const ArrowImg = styled.img`
  display: flex;
  width: 24px;
  height: 24px;
  padding-right: 10px;
`;

const Text = styled.text`
  display: flex;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 20px;
  font-family: Inter;
  font-weight: 400;
`;

const ModalLink = styled.a`
  display: flex;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 700;
  font-family: Inter;
  padding-bottom: 5px;
`;

const SupportModalMobile = ({ closeModal }) => (
  <MobileMenuBackdrop>
    <MobileMenuContent>
      <CloseButton onClick={closeModal} src={cross} alt="close" />
      <ModalTitle>Поддержка</ModalTitle>
      <ModalText>
        Напишите нам, если у вас появились
        <br /> вопросы или предложения.
      </ModalText>
      <SupportContainer>
        <SupportImg src={folder} alt="Folder" />
        <SupportTextContainer>
          <ModalLink href="mailto:support@mirolang.ru">
            support@mirolang.ru
          </ModalLink>
          <Text>Email для обратной связи</Text>
        </SupportTextContainer>
        <ArrowImg src={arrowRight} alt="Arrow" />
      </SupportContainer>
    </MobileMenuContent>
  </MobileMenuBackdrop>
);

export default SupportModalMobile;
