import React from "react";
import styled from "styled-components";
import cross from "../assets/images/cross.svg";
import folder from "../assets/images/folder.png";
import arrowRight from "../assets/images/arrowRight.svg";

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: #282b34;
  padding: 20px;
  border-radius: 20px;
  width: 412px;
  height: 244px;
  max-width: 400px;
  text-align: center;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const SupportContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(61, 65, 73, 1);
  width: 348px;
  height: 80px;
  border-radius: 16px;
  margin: 0 auto;
  padding: 0 10px;
  margin-bottom: 20px;
  cursor: pointer; /* Change cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */

  &:hover {
    background-color: rgba(75, 80, 90, 1); /* New background color on hover */
  }
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 0px;
  font-family: Inter;
`;

const ModalText = styled.p`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 30px;
  font-family: Inter;
`;

const SupportTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin-left: 10px;
  //   margin-top: 19px;
`;

const SupportImg = styled.img`
  padding: 0 5px 0 15px;
  width: 48px;
  height: 48px;
`;

const ArrowImg = styled.img`
  width: 24px;
  height: 24px;
  padding-right: 10px;
`;

const Text = styled.text`
  display: flex;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  font-family: Inter;
  font-weight: 400;
`;

const ModalLink = styled.a`
  display: flex;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 700;
  font-family: Inter;
  padding-bottom: 5px;
`;

const SupportModal = ({ closeModal }) => (
  <ModalBackdrop onClick={closeModal}>
    <ModalContent onClick={(e) => e.stopPropagation()}>
      <CloseButton onClick={closeModal} src={cross} alt="close" />
      <ModalTitle>Поддержка</ModalTitle>
      <ModalText>
        Напишите нам, если у вас появились
        <br />
        вопросы или предложения.
      </ModalText>
      <SupportContainer
        onClick={() => window.open("mailto:support@mirolang.ru")}
      >
        <SupportImg src={folder} alt="Folder" />
        <SupportTextContainer>
          <ModalLink as="div">support@mirolang.ru</ModalLink>
          <Text>Email для обратной связи</Text>
        </SupportTextContainer>
        <ArrowImg src={arrowRight} alt="Arrow" />
      </SupportContainer>
    </ModalContent>
  </ModalBackdrop>
);

export default SupportModal;
