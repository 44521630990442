import React from "react";
import styled from "styled-components";
import PhoneImage from "../assets/images/level.png";
import MolniyaImage from "../assets/images/molniya.png";

const BackgroundContainer = styled.div`
  // background-color: #0c0c0d;
  padding: 20px 0 0 0;
  color: #ffffff;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 1126px;
  @media (max-width: 768px) {
    padding-top: 60px;
  }
`;

const LightningTextSection = styled.section`
  color: #ffffff;
  margin-bottom: 120px;

  p {
    color: #ffffff;
    font-size: 32px;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 5px;
    font-family: Inter;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const Pmobile = styled.section`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    color: #ffffff;
font-family: Inter;
font-size: 20px;
font-weight: 400;
line-height: 28px;
text-align: center;
margin-bottom : -80px;
justify-content: center;
  align-items: center;
  }
}

  
`;

const LightningIcon = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
`;

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(181, 205, 231, 0.1);
  box-shadow: 0px -0.91px 0.91px 0px rgba(0, 0, 0, 0.3) inset;

  box-shadow: 0px 0.91px 0.91px 0px rgba(255, 255, 255, 0.3) inset;

  padding: 20px;
  text-align: center;
  color: #ffffff;
  border-radius: 48px;
  width: 100%;
  max-width: 1136px;
  position: relative;
  @media (max-width: 768px) {
    width: 350px;
    height: 540px;
    padding: 0;
    // margin: 0 20px 0 20px;
    margin: 0 auto;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: #000000;
  border-radius: 33px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 335px;
    height: 525px;
    border-radius: 40px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  max-width: 50%;
  padding: 20px 50px 15px 70px;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 10px;

    text-align: center;
  }

  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;

    margin: -20px 0px 70px 0px;

    @media (max-width: 768px) {
      font-size: 14px;
      margin: -12px 0px 10px 0;
      line-height: 1;
    }
  }
`;

const PhoneMockup = styled.div`
  position: absolute;
  z-index: 1000;
  right: 50px;
  top: -60px;
  @media (max-width: 768px) {
    position: relative;
    display: flex;
    right: 0;
    top: 0;
  }

  img {
    width: 404px;
    height: 468px;
    border-radius: 16px;

    @media (max-width: 768px) {
      width: 302px;
      height: 348px;
      position: relative;
      right: 0;
      top: 0;
    }
  }
`;

const MainText = styled.h1`
  color: #ffffff;
  font-size: 36px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 50px;
  span {
    color: rgba(251, 193, 91, 1);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const MainTextMobile = styled.h1`
  display: none;

  @media (max-width: 768px) {
    display: block;
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    margin-top: -10px;

    span {
      color: rgba(251, 193, 91, 1);
    }
  }
`;

const NextFeatures = () => {
  return (
    <BackgroundContainer>
      <LightningTextSection>
        <LightningIcon src={MolniyaImage} alt="Молния" />
        <p>
          В приложении вы найдёте <br /> английские слова, которые разбиты{" "}
          <br /> на 25 уровней. Начни с простых слов <br /> и дойди до самых
          сложных!
        </p>
        <Pmobile>
          В приложении вы найдёте <br /> английские слова, которые
          <br /> разбиты на 25 уровней. Начни с<br /> простых слов и дойди до
          самых
          <br />
          сложных!
        </Pmobile>
      </LightningTextSection>

      <Section>
        <Content>
          <Card>
            <MainText>
              В приложении собрано
              <br /> 6,500 слов, а это{" "}
              <span>
                98% всей
                <br /> лексики
              </span>
            </MainText>
            <MainTextMobile>
              В приложении собрано 6,500 <br /> слов, а это{" "}
              <span>98% всей лексики</span>
            </MainTextMobile>
            <p>
              Конечно же, слова разбиты по уровням
              <br /> сложности от самых простых и по нарастающей.
            </p>
          </Card>

          <PhoneMockup>
            <img src={PhoneImage} alt="Phone with app" />
          </PhoneMockup>
        </Content>
      </Section>
    </BackgroundContainer>
  );
};

export default NextFeatures;
