import styled from "styled-components";
import { lighten } from "polished";

export const Button = styled.button`
  background-color: #ffcc00;
  color: #000;
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  margin-top: 10px;

  &:hover {
    background-color: ${lighten(0.1, "#ffcc00")};
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 16px;
  }
`;
