import React from "react";
import styled from "styled-components";

import icon1 from "../assets/images/icon_1.png";
import icon2 from "../assets/images/icon_2.png";
import icon3 from "../assets/images/icon_3.png";
import icon4 from "../assets/images/icon_4.png";
import logo from "../assets/images/logo.png";

const BackgroundContainer = styled.div`
  // background-color: #0c0c0d;
  padding: 40px 0 0 0;
  color: #ffffff;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 1126px;
  @media (max-width: 768px) {
    padding: 20px 0 0 0;
  }
`;

const Section = styled.section`
  display: flex;
  justify-content: flex-direction;
  align-items: center;
  background-color: rgba(181, 205, 231, 0.1);
  box-shadow: 0px -0.91px 0.91px 0px rgba(0, 0, 0, 0.3) inset;

  box-shadow: 0px 0.91px 0.91px 0px rgba(255, 255, 255, 0.3) inset;

  padding: 20px;
  text-align: center;
  color: #ffffff;
  border-radius: 48px;
  width: 100%;
  max-width: 1136px;
  height: 500px;
  position: relative;
  @media (max-width: 768px) {
    width: 350px;
    height: 656px;
    padding: 0;
    margin: 0 auto;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: #000000;
  border-radius: 33px;
  height: 500px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 335px;
    height: 640px;
    margin-left: 8px;
    border-radius: 40px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  max-width: 50%;
  padding: 20px 50px 20px 70px;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 20px 5px 10px 5px;
    align-items: center;
    text-align: center;
  }

  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;

    margin: -20px 0px 70px 0px;
    @media (max-width: 768px) {
      line-height: 1;
      font-size: 14px;
      margin: -10px 0px 5px 0;
    }
  }
`;

const FeaturesList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  max-width: 38%;
  margin: 30px 20px 30px 0;
  @media (max-width: 768px) {
    max-width: 90%;
    width: 100%;
    // margin: 0 auto;
    margin-top: 20px;
    margin-left: 20px;
    text-align: center;
  }
`;
const Img = styled.img`
  width: 120px;
  height: 120px;
  @media (max-width: 768px) {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 0 auto;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  margin: 5px;
  background-color: rgba(20, 22, 27, 1);
  border-radius: 20px;
  width: 88%;
  height: 98px;
  img {
    width: 40px;
    height: 40px;
    margin: 0 15px 0 15px;
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    margin: 0;
  }
  @media (max-width: 768px) {
    width: 302px;
    height: 74px;
    margin: 0 0 10px 0;
    border-radius: 12px;
    img {
      width: 48px;
      height: 48px;
    }

    p {
      color: rgba(255, 255, 255, 0.8);
      font-size: 18px;
      margin: 0;
    }
  }
`;

const MainText = styled.h1`
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 16px;
  span {
    color: #ffcc00;
  }

  @media (max-width: 768px) {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
  }
`;

const Title = styled.div`
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  font-family: Inter;
  @media (max-width: 768px) {
    padding-top: 3px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
  }
`;
const SubText = styled.div`
  color: rgba(255, 255, 255, 0.5);

  font-size: 16px;
  font-weight: 400;
  font-family: Inter;
  @media (max-width: 768px) {
    padding-top: 3px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  }
`;

const Text = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  line-height: 1.5;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Button = styled.button`
  margin-top: -40px;
  background-color: #f1cc06;
  width: 242px;
  height: 52px;
  border: none;
  color: #14161b;
  padding: 10px 10px;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    background-color: #e6b800;
  }
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    width: 172px;
    height: 40px;
    padding: 12px;
    border-radius: 12px;
    margin: 0 auto;
    margin-top: 15px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
  }
`;

const SpecialOffers = () => {
  const arrData = [
    {
      id: 1,
      title: "Безлимит карточек",
      text: "Неограниченное количество карточек каждый день.",
      icon: icon1,
    },
    {
      id: 2,
      title: "Аналитика",
      text: "Отслеживайте ваш прогресс в изучении нового языка.",
      icon: icon2,
    },
    {
      id: 3,
      title: "Безлимит списков и слов",
      text: "Создавайте неограниченное количество списков и слов.",
      icon: icon3,
    },
    {
      id: 4,
      title: "Отсутствие рекламы",
      text: "Ничто не будет вас отвлекать от изучения нового языка.",
      icon: icon4,
    },
  ];

  return (
    <BackgroundContainer>
      <Section>
        <Content>
          <Card>
            <Img src={logo} alt="logo" />
            <MainText>
              Хотите учить слова
              <br /> в 4 раза быстрее?
            </MainText>
            <Text>
              Запоминайте слова без ограничений
              <br /> в 4 раза быстрее с MiroLang Pro.
            </Text>
            <Button
              onClick={() =>
                window.open(
                  "https://apps.apple.com/by/app/mirolang-%D1%83%D1%87%D0%B8-%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B5-%D1%81%D0%BB%D0%BE%D0%B2%D0%B0/id6503367928",
                  "_blank"
                )
              }
            >
              Скачать приложение
            </Button>
          </Card>

          <FeaturesList>
            {arrData.map((el) => (
              <FeatureItem key={el.id}>
                <img
                  style={{ width: 56, height: 56 }}
                  src={el.icon}
                  alt={el.title}
                />
                <div style={{ marginBottom: 5 }}>
                  <Title>{el.title}</Title>
                  <SubText>{el.text}</SubText>
                </div>
              </FeatureItem>
            ))}
          </FeaturesList>
        </Content>
      </Section>
    </BackgroundContainer>
  );
};

export default SpecialOffers;
